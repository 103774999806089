import React, { useState } from "react";
import { connect } from 'react-redux';
import { Button, Grid, Paper, LinearProgress, Input } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";

// styles
import useStyles from "./styles";

// API
import { formatGetS3SignedURL, formatInsertTaskURL } from '../../utils/apis';

function TaskCreationPage(props) {
  var classes = useStyles();
  const { accessTokenState } = props;
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleErrorOpen = () => {
    setErrorOpen(true);
  }

  const handleErrorClose = () => {
    setErrorOpen(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFileInputChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    const file = files[0];
    let reader = new FileReader();
    let audio = new Audio();
    reader.onload = (e) => {
      if (!e.target.result.includes('data:audio/mpeg')) {
        return alert('Wrong file type - audio/mpeg only.');
      }

      audio = e.target.result;
      triggerUpload(audio);
    }
    reader.readAsDataURL(file);
  };

  const triggerUpload = (audio) => {
    setIsPageLoading(true);
    // Get the presigned URL
    axios.get(
      formatGetS3SignedURL(),
    {
      headers: {
        'Cache-Control' : 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Api-Key': '*',
        'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
        Authorization: `Bearer ${accessTokenState}`
      }
    }).then(response => {
      // Upload to s3
      let binary = atob(audio.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], {type: 'audio/mpeg'});

      axios({
        method: 'PUT',
        url: response.data.uploadURL,
        data: blobData
      },
      {
        headers: {
          'Cache-Control' : 'no-cache',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Api-Key': '*',
          'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
          Authorization: `Bearer ${accessTokenState}`
        }
      }).then(result => {
        // Because s3 won't send header with event, we do this on client side.
        // console.log('Result: ', result)
        // Final URL for the user doesn't need the query string params
        let s3InputFileLocation = response.data.uploadURL.split('?')[0];
        // console.log(s3InputFileLocation);

        // Call API to insert the task into DB
        axios.post(formatInsertTaskURL(),
          {
            data: {
              's3InputFileLocation': s3InputFileLocation,
              'engine': 'ASR'
            }
          },
          {
            headers: {
              'Cache-Control' : 'no-cache',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'X-Api-Key': '*',
              'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
              Authorization: `Bearer ${accessTokenState}`,
            }
          }
        ).then(resp => {
          // console.log('after update DB');
          // console.log(resp);
          if(resp['data']['statusCode'] === 400) {
            handleErrorOpen();
          } else {
            handleClickOpen();
          }
          setIsPageLoading(false);
        })
      });
    }).catch(e => {
      console.error('something went wrong when uploading, try again', e);
      setIsPageLoading(false);
    })
  }

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
    <Dialog
      open={errorOpen}
      onClose={handleErrorClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You exceed your free quota, please contact us for an upgrade.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleErrorClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, please check back later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
    </Dialog>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        alignItems="center"
        justify="center"
      >
        <Grid container>
          <Grid item md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box m={5}>
              <Widget title="Upload" disableWidgetMenu>
                <div className={classes.dashedBorder}>
                  <Typography className={classes.text} size="md">
                    Please upload *.mp3 audio file.
                  </Typography>
                  <Typography className={classes.text} size="md">
                    System will auto detect language and translate audio file to *.txt
                  </Typography>
                  <Typography className={classes.text} size="md">
                    We uses a deep learning process called automatic speech recognition (ASR) to convert speech to text quickly and accurately. Avery AI can be used to transcribe customer service calls, automate subtitling, and generate metadata for media assets to create a fully searchable archive.
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2} md={5}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={8} md={4}>
            <Box m={5}>
              <label htmlFor="upload-photo">
                <Input
                  style={{ display: 'none' }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={onFileInputChange}
                />

                <Button color="secondary" variant="contained" component="span">
                  Select file
                </Button>
              </label>
            </Box>
          </Grid>
          <Grid item xs={2} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </div>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(TaskCreationPage);