import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ViewList,
  ArrowBack as ArrowBackIcon,
  Create as CreateIcon,
  QuestionAnswer as SupportIcon,
  DataUsage,
  AttachMoney,
  Voicemail
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";

// Routes
import {
DASHBOARD,
TASKS,
CREATE,
CREATE_DEEP,
USAGE,
RECENT,
STARRED,
UPGRADE,
SUPPORT
} from '../../utils/constants';

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

import { toggleSidebar } from '../../reducerCollection/ThemeOptions';

const structure = [
  { label: "Home", link: DASHBOARD, icon: <HomeIcon /> },
  { label: "Create", link: CREATE, icon: <CreateIcon /> },
  { label: "Deep Speech", link: CREATE_DEEP, icon: <Voicemail /> },
  { label: "Tasks", link: TASKS, icon: <ViewList /> },
  { label: "Usage", link: USAGE, icon: <DataUsage /> },
  { type: "divider" },
  { type: "title", label: "PROJECTS" },
  { label: "My recent", link: RECENT, icon: <Dot size="small" color="warning" /> },
  { label: "Starred", link: STARRED, icon: <Dot size="small" color="secondary" /> },
  { type: "divider" },
  { type: "title", label: "HELP" },
  { label: "Upgrade", link: UPGRADE, icon: <AttachMoney /> },
  { label: "Support", link: SUPPORT, icon: <SupportIcon /> }
];

function Sidebar(props) {
  const {
    isSidebarOpened
  } = props;
  var location = useLocation();

  var classes = useStyles();
  var theme = useTheme();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => props.toggleSidebar()}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((element, key) => (
          <SidebarLink
            key={key}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...element}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

const mapStateToProps = state => ({
  isSidebarOpened: state.ThemeOptions.isSidebarOpened
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: ()  => dispatch(toggleSidebar()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);