import React from "react";
import {
  Button,
  IconButton,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@material-ui/core";
import useStyles from "./styles";

const states = {
  done: "success",
  processing: "warning",
  declined: "secondary",
};

const KEY_ARRAY= ['CreatedAt', 'UpdatedAt', 'Engine', 'DownloadURL', 'Status'];

export default function TableComponent({ data }) {

  const classes = useStyles();
  if(!Array.isArray(data) || !data.length || !data[0]) {
    return (null);
  }

  return (
    <TableContainer>
      <Table className="mb-0">
      <TableHead>
        <TableRow>
          {KEY_ARRAY.map(key => (
            <TableCell style={{
            }} key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((element) => {
          let { id, status, outputLocation, engine, createdAt, updatedAt} = element;
          return (
          <TableRow key={id}>
            <TableCell>{createdAt}</TableCell>
            <TableCell>{updatedAt}</TableCell>
            <TableCell>{engine}</TableCell>
            <TableCell>
              {
                outputLocation ?
                <Button
                  target="_blank"
                  href={outputLocation}
                  color="primary"
                  variant="contained"
                >
                  Download
                </Button> :
                <Button
                  disabled
                  variant="contained"
                >
                  Not Available
                </Button>
              }
            </TableCell>
            <TableCell>
              <Chip label={status} classes={{root: classes[states[status.toLowerCase()]]}}/>
            </TableCell>
          </TableRow>
          )
        })}
      </TableBody>
    </Table>
    </TableContainer>

  );
}
