import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";
import moment from 'moment';


export default function ApexLineChart(props) {
  var theme = useTheme();

//   const series = [
//   {
//     name: "series1",
//     data: [31, 40, 28, 51, 42, 109, 100],
//   },
//   {
//     name: "series2",
//     data: [11, 32, 45, 32, 34, 52, 41],
//   },
// ];

  const { totalCreatedByMonth, totalSucceedByMonth } = props;
  const series = [
    {
      name: "created",
      data: totalCreatedByMonth
    },
    {
      name: "succeed",
      data: totalSucceedByMonth
    }
  ]

  return (
    <ApexCharts
      options={themeOptions(theme)}
      series={series}
      type="area"
      height={350}
    />
  );
}

// ############################################################
function themeOptions(theme) {
  const now = new Date().getTime();
  const MONTH_IN_MILL = 604800000 * 4;
  let xAxisArray = [];
  for(let i = 0; i<6; i++) {
    xAxisArray.push(moment(now - MONTH_IN_MILL*i).format("MMM DD"));
  }

  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: xAxisArray.reverse()
    },
    tooltip: {
      x: {
        format: "MM/dd",
      },
    },
    fill: {
      colors: [theme.palette.primary.light, theme.palette.success.light],
    },
    colors: [theme.palette.primary.main, theme.palette.success.main],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };
}
