const API_DOMAIN = 'https://0oy30vdsdl.execute-api.us-east-1.amazonaws.com/prod';

// used for mp3
export const formatGetS3SignedURL = () => {
  return `${API_DOMAIN}/s3`;
}

// used for wav
export const formatGetS3SignedWAVURL = () => {
  return `${API_DOMAIN}/s3wav`;
}

export const formatGetAllTasksURL = () => {
  return `${API_DOMAIN}/tasks?taskId=*`;
}

export const formatInsertTaskURL = () => {
  return `${API_DOMAIN}/tasks`;
}

export const formatUpdateTaskURL = (taskId) => {
  return `${API_DOMAIN}/tasks/${taskId}`;
}


export const formatGetUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatCreateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateSupportURL = () => {
  return `${API_DOMAIN}/support`;
}