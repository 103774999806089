//DOMAIN
export const LOCAL_HOST = "http://localhost:3000/"; // Used for dev
export const DOMAIN = "https://www.averyai.co/" // Use for marketing page, log out redirect
export const SUB_DOMAIN = "https://app.averyai.co/" // Use for real app, log in redirect

// Auth0
export const AUTH0_CLIENT_ID = 'WKL02KtdpjsaCHUoC03S1cYAdbahLAvt';
export const AUTH0_CLIENT_SECRET = 'hWypaVRbioqH1BTBjI7DLwTFFH_Xp8wnwhZhb1DV-8NITESNC29k_48x5gMSOskn';
export const AUTH0_DOMAIN = 'dev-es09f6cx.us.auth0.com';
export const AUTH0_AUDIENCE = `https://your-api-gateway/`;
export const RESET_PS_URI = `https://${AUTH0_DOMAIN}/dbconnections/change_password`;
export const AUTH0_LOGIN_REDIRECT_URI = `${SUB_DOMAIN}`; // this needs to match auth0.com
export const AUTH0_LOGOUT_REDIRECT_URI = `${DOMAIN}`; // this needs to match auth0.com

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// Routes
export const INTRO = '/';
export const DASHBOARD = '/dashboard';
export const TASKS = '/tasks';
export const CREATE = '/create';
export const CREATE_DEEP = '/createdeep';

export const USAGE = '/usage';
export const RECENT = '/recent';
export const STARRED = '/starred';
export const UPGRADE = '/upgrade';
export const SUPPORT = '/support';
export const PROFILE = '/profile';
export const ERROR_500 = '/error500';
export const CATCH_ALL = '/*';
